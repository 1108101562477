import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrl: './maintenance.component.scss'
})
export class MaintenanceComponent {

  private clickCount = 0;

  constructor(
    private _router: Router
  ) {
  }

  handleImageClick() {
    this.clickCount++;
    if (this.clickCount === 10) {
      document.cookie = "vini_access=authorized; Path=/; Expires=Tue, 20 Feb 2025 23:59:59 GMT; Secure";
      this._router.navigate(['/']);
    }
  }
}
