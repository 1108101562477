import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { ConsoleService } from '../../console/console.service';
import { CustomerFile } from '../../../interfaces/customer-file';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ToolsService } from '../../tools/tools.service';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerFileService {

  customerFile: CustomerFile;
  customerForm: FormGroup;
  otpForm: FormGroup;
  showPhoneField: boolean = false;
  revendeur: boolean = false;

  constructor(
    private _apiService: ApiService,
    private _consoleService: ConsoleService,
    private _toolsService: ToolsService,
    private fb: FormBuilder,
    private _router: Router,
    private _notificationService: NotificationService
  ) { }

  setCustomerFile(customer: CustomerFile) {
    this.customerFile = customer;
    this.initForms();
  }

  initCustomerFile() {
    this.customerFile = {
      customerId:0,
      customerFileId:0,
      phone:"",
      civility:"",
      lastname:"",
      firstname:"",
      birthdate:"",
      otp_code:"",
      language: localStorage.getItem('language') || 'fr',
      esim:false
    };
    const token = localStorage.getItem('token');
    if(token && (token.length == 32)){
      // localStorage.clear();
      localStorage.setItem('token', token);
      this.getCustomerFileByToken(token);
    }
  }

  getCustomerFileByToken(token, updateStepper = true) {
    this._apiService.get('customer-files/token/' + token).subscribe((data: any) =>{
      this._consoleService.debug('CustomerFileService - getCustomerFileByToken', 'o', 'data', data);

      if(updateStepper) this.updateStepper(data, token);

      this.setCustomerFile(data.data);
    });
  }

  getCustomerFileBySimNumber(simNumber) {
    this._notificationService.showLoading();
    this._apiService.get('customer-files/sim/' + simNumber).subscribe(
      (data: any) =>{
        this._consoleService.debug('CustomerFileService - getCustomerFileBySimNumber', 'b', 'data', data);
        if(!data.data) {
          this._consoleService.debug('CustomerFileService - getCustomerFileBySimNumber', 'r', 'no SIM');
          this._notificationService.showNotification('Désolé', data.message);
          return;
        } else {
          this._consoleService.debug('CustomerFileService - getCustomerFileBySimNumber', 'g', 'data.token', data.token);
          localStorage.setItem('token', data.token);

          this.initCustomerFile();
          this._router.navigate(['identification/' + data.toke]);
        }
      },
      (err: any) => {
        this._consoleService.debug('CustomerFileService - getCustomerFileBySimNumber', 'o', 'err', err);
      }
    );
  }

  updateStepper(data, token){
    this._consoleService.debug('CustomerFileService - updateStepper', 'o', 'data', data);
    this._consoleService.debug('CustomerFileService - updateStepper', 'o', 'token', token);
    if(localStorage.getItem('revendeur')){
      return;
    }
    if(data.data.hasOwnProperty('trust_and_sign_client')){
      if(data.data.trust_and_sign_client.state === "SUSPENDED"){
        // localStorage.setItem('stepper', '1');
        this._router.navigate(['/en-cours-de-verification']);
      } else if(data.data.trust_and_sign_client.state === "ACCEPTED"){
        localStorage.setItem('idenficationIsDone', "true");
        if(data.data.consent){
          localStorage.setItem('consentIsDone', "true");
          localStorage.setItem('stepper', '3');
          this._router.navigate(['/felicitations/' + token]);
        }else{
          localStorage.setItem('stepper', '2');
          this._router.navigate(['/identification/' + token]);
        }
      }
    } else {
      localStorage.setItem('stepper', '0');
    }
  }

  updateCustomerFileFromTrustNsign(event){
    this._consoleService.debug('CustomerFileService - updateCustomerFileFromTrustNsign', 'b', 'this.customerFile', this.customerFile);
    if(this.customerFile){
      let notifications = [
        {
          "id": 167842,
          "date": "2018-10-31T08:46:02.956Z",
          "event": event,
          "clientFileUuid": this.customerFile.clientFileUuid,
        }
      ]
      this._apiService.post('netheos/webhook', {notifications: JSON.stringify(notifications)}).subscribe((data) => {
        this._consoleService.debug('CustomerFileService - updateCustomerFileFromTrustNsign', 'o', 'data', data);
      });
    }
  }

  initForms() {
    const birthdateString = this.customerFile.birthdate; // "2000-12-12T10:00:00.000000Z"
    const date = birthdateString ? new Date(birthdateString) : null;

    let birthdateNgb: NgbDateStruct | null = null;
    if (date && !isNaN(date.getTime())) {
      birthdateNgb = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      };
    }

    this.customerForm = this.fb.group({
      phone: [
        this.customerFile.phone,
        [
          Validators.required,
          phoneOrSimValidator(),
        ],
      ],
      civility: [
        this.customerFile.civility,
        Validators.required,
      ],
      lastname: [
        this.customerFile.lastname,
        Validators.required,
      ],
      firstname: [
        this.customerFile.firstname,
        Validators.required,
      ],
      birthdate: [
        birthdateNgb,
        [Validators.required, this._toolsService.ageValidator(18)],
      ],
    }
  );


    // Ajout des champs si revendeur est true
    if (this.revendeur) {
      this.customerForm.addControl('document_type', this.fb.control('', Validators.required));
      this.customerForm.addControl('document_number', this.fb.control('', Validators.required));
      // this.customerForm.addControl('sim_number',this.fb.control('', [Validators.pattern(/^[0-9]{19}$/)]));
    }
    // } else {
    //   const phoneControl = this.customerForm.get('phone');
    //   if (phoneControl) {
    //     // Add Validators
    //     phoneControl.setValidators([Validators.required]);
    //     // Update validity after adding validators
    //     // phoneControl.updateValueAndValidity();
    //   }
    // }

    this.otpForm = this.fb.group({
      otp_code: ['', [Validators.required, Validators.pattern(/^[0-9]{6}$/)]],
    });

    this.initFormWithCustomerFile();
  }

  initFormWithCustomerFile(){

    const birthdateString = this.customerFile.birthdate; // "2000-12-12T10:00:00.000000Z"
    const date = birthdateString ? new Date(birthdateString) : null;

    let birthdateNgb: NgbDateStruct | null = null;
    if (date && !isNaN(date.getTime())) {
      birthdateNgb = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      };
    }

    this._consoleService.debug('CustomerFileService - initFormWithCustomerFile', 'b', 'this.customerFile', this.customerFile);
    this.customerForm.patchValue({
      phone: this.customerFile.phone,
      civility: this.customerFile.civility,
      lastname: this.customerFile.lastname,
      firstname: this.customerFile.firstname,
      birthdate: birthdateNgb,
    });
  }

  clear(){

    localStorage.removeItem('token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('idenficationIsDone');
    localStorage.removeItem('trust_and_sign_client_file_uuid');
    localStorage.removeItem('name');
    localStorage.removeItem('language');

    this._consoleService.debug('CustomerFileService - clear', 'b', "localStorage.getItem('revendeur')", localStorage.getItem('revendeur'));
    if(!localStorage.getItem('revendeur')){
      // window.history.replaceState({}, '', '/identification');
    }

    this.customerFile = null;
    this.showPhoneField = true;
    this.initCustomerFile();
    this.initForms();

  }
}

export function phoneOrSimValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    // Pattern pour téléphone
    const phonePattern = /^(?:\+689|689)?87[0-9]{6}$/;
    // Pattern pour numéro SIM
    const simPattern = /^[0-9]{19}$/;

    // Vérifie si au moins un des deux patterns correspond
    if (phonePattern.test(value) || simPattern.test(value)) {
      return null; // Valide
    }

    return { phoneOrSimInvalid: true }; // Retourne une erreur personnalisée
  };
}
