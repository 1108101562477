import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SidebarService } from '../../shared/services/sidebar/sidebar.service';
import { AnimationOptions } from 'ngx-lottie';
import { Router } from '@angular/router';
import { ConsoleService } from '../../shared/services/console/console.service';
import { SettingService } from '../../shared/services/model/setting/setting.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { CustomerFileService } from '../../shared/services/model/customerFile/customer-file.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
declare var NthSignbook: any;
@Component({
  selector: 'app-identification',
  templateUrl: './identification.component.html',
  styleUrl: './identification.component.scss'
})
export class IdentificationComponent implements OnInit, AfterViewInit {

  env = environment;

  active: number = 0;
  token: string;
  sidebarVisible: boolean = false;
  options: AnimationOptions = {
    path: 'assets/animations/vini-flying.json'
  };

  @ViewChild('firstStepper', { static: false }) firstStepper!: ElementRef;
  @ViewChild('secondStepper', { static: false }) secondStepper!: ElementRef;
  @ViewChild('thirdStepper', { static: false }) thirdStepper!: ElementRef;
  stepperPosition: number = 12;
  stepperWidth: number = 100;

  showNetheosIframe: boolean = false;
  netheosIframeLoaded: boolean = false;
  idenficationIsDone: boolean = false;

  constructor(
    public sidebarService: SidebarService,
    private router: Router,
    private _consoleService: ConsoleService,
    private _settingService: SettingService,
    private _customerService: CustomerFileService,
    private _notificationService: NotificationService,
    private _translateService: TranslateService
  ){
    this.active = parseInt(localStorage.getItem('stepper') || "0");
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getStepperPosition(this.active)
    }, 0);
  }

  getStepperPosition(stepper: number) {
    if(stepper == 1){
      this.showNetheosFrame(stepper);
      return;
    } else if(stepper == 2){
      this.showClickAndWrapForm(stepper);
      return;
    } else {
      this.active = stepper;
      this.showNetheosIframe = false;
      setTimeout(() => {
        const target = this.firstStepper.nativeElement;
        this.moveSliderTabs(target, stepper);
      },100);
    }
  }

  moveSliderTabs(target, stepper){
    localStorage.setItem('stepper', stepper);
    this._consoleService.debug("IdentificationComponent - moveSlideTabs", "b");
    const rect = target.getBoundingClientRect();

    const parent = target.parentElement;
    const parentRect = parent?.getBoundingClientRect();

    if (parentRect) {
      this.stepperPosition = rect.left - parentRect.left;
      this.stepperWidth = rect.width;
    } else {
      this.stepperPosition = rect.left;
    }

    document.getElementById('slider').style.width = this.stepperWidth + 'px';
    document.getElementById('slider').style.left = this.stepperPosition + 'px';
  }

  goToWelcome(){
    this.router.navigate(['/']);
  }

  /**
   *
   * Trust And Sign START
   */
  loadNthSignbookScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (document.getElementById('nthsignbook-script')) {
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.id = 'nthsignbook-script';
      script.src = this.env.tnsLink;
      script.onload = () => {
        resolve();
      };
      script.onerror = () => {
        reject(new Error('Failed to load NthSignbook script'));
      };
      document.body.appendChild(script);
    });
  }

  showNetheosFrame(stepper){
    this._notificationService.showLoading();
    setTimeout(()=>{
      if(!this.netheosIframeLoaded){
        const url = this._settingService.getByKey('tns_iframe_url'); //https://demo-api.ekeynox.net/contract/signbook/signbook.html';
        const token = localStorage.getItem('access_token'); // Si le token est stocké dans le localStorage
        const lang = localStorage.getItem('language') || 'fr'; // Si la langue est stockée dans le localStorage
        this._consoleService.debug('IdentificationComponent - showNetheosFrame', 'b', 'url', url);
        this._consoleService.debug('IdentificationComponent - showNetheosFrame', 'b', 'lang', lang);
        //If no token then no informations submission
        if (!lang || !token) {
          this._notificationService.showNotification(this._translateService.instant("information_error_required_title"), this._translateService.instant("information_error_required_description"));
          return;
        }

        this.showNetheosIframe = true;

        this.loadNthSignbookScript()
          .then(() => {
            this.netheosIframeLoaded = true;
            this.initializeNthSignbook(url, token, lang == "pf" ? "pt" : lang);
          })
          .catch((error) => {
            console.error('Erreur lors du chargement du script NthSignbook:', error);
          });
      } else {
        this._notificationService.hideNotification();
        this.showNetheosIframe = true;
      }

      this.active = 1;
      // const target = this.secondStepper.nativeElement;
      // this.moveSliderTabs(target, stepper);
    }, 3000);
  }

  initializeNthSignbook(url: string, token: string, lang: string): void {
    this._consoleService.debug('IdentificationComponent - initializeNthSignbook', 'b', 'url', url);
    this._consoleService.debug('IdentificationComponent - initializeNthSignbook', 'b', 'token', token);
    this._consoleService.debug('IdentificationComponent - initializeNthSignbook', 'b', 'lang', lang);
    const signbook = new NthSignbook({
      iframeSelectorId: 'signbookIframe',
      url: url,
      options: {
        lang: lang,
        renderMode: 'pretty',
        callbackURL: this.env.appUrl + '/',
      },
      token: token,
      onUserEventMessage: this.onUserEventMessageFn.bind(this),
      onClientFileEventMessage: this.onClientFileEventMessageFn.bind(this),
      onErrorEventMessage: this.onErrorEventMessageFn.bind(this),
      onDisplayEventMessage: this.onDisplayEventMessageFn.bind(this),
      onExitEventMessage: this.onExitEventMessageFn.bind(this),
    });

    this.active = 1;
    // const target = this.secondStepper.nativeElement;
    // if(target)
    // this.moveSliderTabs(target, 1);
  }

  onUserEventMessageFn(event: any): void {
    this._consoleService.debug("onUserEventMessageFn", "o", "event", event);
    // "step-10"

    if(event.page === "step-error"){
      this._consoleService.debug("onUserEventMessageFn", "o", "event.page", event.page);
      this._customerService.updateCustomerFileFromTrustNsign("TO_UPDATE");
    }

    if(event.action === "EXIT_AFTER_SUSPENSION"){
      this._consoleService.debug("onUserEventMessageFn", "o", "event.action", event.action);
      this._customerService.updateCustomerFileFromTrustNsign("SUSPENDED");
    }
  }

  onClientFileEventMessageFn(event: any): void {
    this._consoleService.debug("onClientFileEventMessageFn", "o", "event", event);

    if(event.state == "ACCEPTED"){
      localStorage.setItem('idenficationIsDone', "true");
      this._customerService.updateCustomerFileFromTrustNsign(event.state);
      this.getStepperPosition(2);
    }
    if(event.state == "WAITING"){
      localStorage.setItem('idenficationIsDone', "true");
      this._customerService.updateCustomerFileFromTrustNsign('TO_UPDATE');
      this.getStepperPosition(2);
    }
    if(event.state == "SUSPENDED"){
      this._consoleService.debug("onUserEventMessageFn", "o", "event.state", event.state);
      this._customerService.updateCustomerFileFromTrustNsign("SUSPENDED");
    }
  }

  onErrorEventMessageFn(event: any): void {
    this._consoleService.debug("onErrorEventMessageFn", "o", "event", event);
  }

  onDisplayEventMessageFn(event: any): void {
    this._consoleService.debug("onDisplayEventMessageFn", "o", "event", event);
    this._notificationService.hideNotification();
  }

  onExitEventMessageFn(event: any): void {
    this._consoleService.debug("onExitEventMessageFn", "o", "event", event);
    // this.getStepperPosition(0);
    window.location.href = "https://www.vini.pf";
  }

  /**
   *
   * Trust And Sign END
   */

  /**
   *
   * Click And Wrap START
   */
  showClickAndWrapForm(stepper) {
    const storedValue = localStorage.getItem('idenficationIsDone');
    this.idenficationIsDone = storedValue !== null ? storedValue === 'true' : false;
    if(!this.idenficationIsDone){
      this._notificationService.showNotification(this._translateService.instant("identification_error_required_title"), this._translateService.instant("identification_error_required_description"));
      return;
    }

    this.showNetheosIframe = false;

    this.active = 2;
    setTimeout(() => {
      const target = this.thirdStepper.nativeElement;
      this.moveSliderTabs(target, stepper);
    }, 1000);
  }

  showLanguageForm = false;
}
