import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/services/api/api.service';
import { CustomerFileService } from '../../../shared/services/model/customerFile/customer-file.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
declare var clickWrap: any;

@Component({
  selector: 'app-namirial-click-wrap',
  templateUrl: './namirial-click-wrap.component.html',
  styleUrls: ['./namirial-click-wrap.component.scss']
})
export class NamirialClickWrapComponent {

  cgu1 = false;
  cgu2 = false;

  token;

  constructor(
    private _apiService: ApiService,
    private customerFileService: CustomerFileService,
    private _router: Router,
    private _notificationService: NotificationService,
    public translateService: TranslateService,
  ) {
    localStorage.getItem('token') ? this.token = localStorage.getItem('token') : this.token = null;
    if(!this.customerFileService.customerFile) this.customerFileService.getCustomerFileByToken(this.token, false);
  }

  submitConsent(){
    this._notificationService.showLoading();
    if(this.cgu1 && this.cgu2){
      this._apiService.post('consent/' + this.customerFileService.customerFile.customerFileId, null).subscribe((data) => {
        this._router.navigate(['/felicitations/' + this.token]);
      });
    } else {

      this._notificationService.showNotification(this.translateService.instant("consent_error_required_title"),this.translateService.instant("consent_error_required_description"));
      return;
    }
  }
}
