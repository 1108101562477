import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from '../../../shared/services/api/api.service';
import { ConsoleService } from '../../../shared/services/console/console.service';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { CustomerFileService } from '../../../shared/services/model/customerFile/customer-file.service';
import { SettingService } from '../../../shared/services/model/setting/setting.service';

import { CustomerFile } from '../../../shared/interfaces/customer-file';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { ToolsService } from '../../../shared/services/tools/tools.service';


interface Genre {
  name: string;
  value: string;
}

@Component({
  selector: 'app-form-id',
  templateUrl: './form-id.component.html',
  styleUrls: ['./form-id.component.scss'],
  providers: [MessageService],
})
export class FormIdComponent implements OnInit {

  @Input() revendeur: boolean = false;
  customer: CustomerFile | undefined;

  genres: Genre[] | undefined;

  identityTypes = [
    { name: "Pièce d'identité", value: "Pièce d'identité" },
    { name: "Passeport", value: "Passeport" },
    { name: "Titre de séjour", value: "Titre de séjour" },
    { name: "Permis de conduire", value: "Permis de conduire" },
  ];

  languages = [
    { name: '🇫🇷', code: 'fr' },
    { name: '🇬🇧', code: 'en' },
    { name: '🇵🇫', code: 'pf' },
  ];

  token: string = '';
  showConsent: boolean = false;

  otpRequest: boolean = false;
  minDate;
  maxDate;
  countdownTime: number; // 5 minutes in seconds
  countdownDisplay: string = '00:00:00';
  private intervalCountdown: any;

  startQrCodeScanner: boolean = false;

  notificationsRevendeur = {
    already_updated: {
      title: "Dossier déjà mis à jour",
      description: "Le dossier et le numéro de téléphone ont déjà été mis à jour",
      callback: "clearCustomerForm"
    },
    updated: {
      title: "Dossier mis à jour",
      description: "Le dossier et le numéro de téléphone ont été mis à jour",
      callback: "clearCustomerForm"
    },
    customer_not_found: {
      title: "Dossier non trouvé",
      description: "Nous ne trouvons malheureusement aucune correspondance. Veuillez réessayer plus à tard.",
      callback: null
    }
  }

  @Output() updateActiveTabs = new EventEmitter<number>();
  @Output() showNetheosFrame = new EventEmitter<boolean>();

  constructor(
    private _apiService: ApiService,
    private _consoleService: ConsoleService,
    public translateService: TranslateService,
    public customerFileService: CustomerFileService,
    private _settingService: SettingService,
    private activatedRoute: ActivatedRoute,
    private _notificationService: NotificationService,
    private _authService: AuthService,
    private _router: Router,
    private _toolsService: ToolsService
  ) {

    this.activatedRoute.paramMap.subscribe(params => {
      const routeToken = params.get('token');
      this._consoleService.debug('FormIdComponent - URL', 'o', 'ActivatedRoute params:', params);

      this.token = routeToken || null;  // Si le paramètre token n'existe pas, on utilise celui dans localStorage
      this._consoleService.debug('FormIdComponent - token', 'o', 'this.token', this.token);

      if(this.token && this.token.length === 32) {
        localStorage.setItem('token', this.token);
        this.customerFileService.showPhoneField = false;
      } else {
        this.customerFileService.clear();
      }
      this.customerFileService.initCustomerFile();
    });

  }

  ngOnInit() {
    this.translateService.get(['input_civility_option_1', 'input_civility_option_2']).subscribe(translations => {
      this.genres = [
        { name: translations['input_civility_option_1'], value: 'M.' },
        { name: translations['input_civility_option_2'], value: 'Mme' },
      ];
    });

    this.customerFileService.revendeur = this.revendeur;
    this.customerFileService.customerFile.language = this.translateService.currentLang;
    const today = new Date();

    this.minDate = {
      year: today.getFullYear() - 100,
      month: today.getMonth() + 1, // Month is 0-based in JS
      day: today.getDate(),
    };

    this.maxDate = {
      year: today.getFullYear() - 18,
      month: today.getMonth() + 1,
      day: today.getDate(),
    };


    // Initialize Reactive Forms
    this.customerFileService.initForms();

    this._notificationService.callbackNotification.subscribe(callback => {
      if(callback == "clearCustomerForm") {
        this.customerFileService.clear();
      } else if(callback == "confirmPhone") {
        this._router.navigate(['/mauruuru']);
      }
    });
  }

  prepareCustomerDataForRequest(): CustomerFile {
    const formValues = this.customerFileService.customerForm.value;
    this._consoleService.debug('FormIdComponent - prepareCustomerDataForRequest', 'b', 'formValues', formValues);
    this._consoleService.debug('FormIdComponent - prepareCustomerDataForRequest', 'b', 'this.customerFileService.customerFile', this.customerFileService.customerFile);

    // Préfixer le numéro de téléphone avec 689 si nécessaire
    // let phone = null;
    // if(formValues.phone.toString()){
      let phone = this._toolsService.formatPhone(formValues.phone.toString());
      this.customerFileService.customerForm.patchValue({
        phone: phone
      });
    // }

    let data = {
      customerId: this.customerFileService.customerFile.customerId,
      customerFileId: this.customerFileService.customerFile.customerFileId,
      phone: formValues.phone.length != 19 ? formValues.phone : null,
      civility: formValues.civility,
      lastname: formValues.lastname,
      firstname: formValues.firstname,
      birthdate: formValues.birthdate.year + '-' + String(formValues.birthdate.month).padStart(2, '0') + '-' +
      String(formValues.birthdate.day).padStart(2, '0'),
      language: localStorage.getItem('language') || 'fr',
    };

    if(this.revendeur){
      (data as any).revendeur = true;
      (data as any).document_type = formValues.document_type;
      (data as any).document_number = formValues.document_number;
      (data as any).sim_number = formValues.phone.length == 19 ? formValues.phone : null;
    }

    return data;
  }

  updateCustomer() {
    if(this.revendeur){
      this.updateCustomerByRevendeur();
      return;
    }

    // Parcourir tous les champs pour les marquer comme "touché" ou "sale"
    Object.keys(this.customerFileService.customerForm.controls).forEach(cle => {
      const controle = this.customerFileService.customerForm.get(cle);
      controle?.markAsTouched(); // Marque comme touché
      controle?.updateValueAndValidity(); // Met à jour la validité
    });

    if (this.customerFileService.customerForm.invalid) {
      this._consoleService.debug('FormIdComponent - updateCustomer', 'r', 'customerForm', this.customerFileService.customerForm.errors);
      return;
    }
    this._consoleService.debug('FormIdComponent - updateCustomer', 'r', 'this._authService.isAuthenticated()', this._authService.isAuthenticated());

    if (this.customerFileService.showPhoneField) {
      this.showOTPForm();
      return;
    } else {
      this.customerFileService.customerForm.controls['phone'].setValue(this.customerFileService.customerFile.phone);
    }

    const customer = this.prepareCustomerDataForRequest();
    this._consoleService.debug( 'FormIdComponent - updateCustomer', 'b', 'customer', customer );

    this._apiService.post('customers/' + this.customerFileService.customerFile.customerId, customer).subscribe((data) => {
      this._notificationService.showLoading();
      if(!this._authService.isAuthenticated()) this.processTrustNsign();
    });
  }

  updateCustomerByRevendeur() {

    // Parcourir tous les champs pour les marquer comme "touché" ou "sale"
    Object.keys(this.customerFileService.customerForm.controls).forEach(cle => {
      const controle = this.customerFileService.customerForm.get(cle);
      controle?.markAsTouched(); // Marque comme touché
      controle?.updateValueAndValidity(); // Met à jour la validité
    });

    if (this.customerFileService.customerForm.invalid) {
      this._consoleService.debug('FormIdComponent - updateCustomerByRevendeur', 'r', 'customerForm', this.customerFileService.customerForm);
      return;
    }
    this._notificationService.showLoading();
    const customer = this.prepareCustomerDataForRequest();
    this._consoleService.debug( 'FormIdComponent - updateCustomerByRevendeur', 'b', 'customer', customer );

    this._apiService.post('edit/customers/revendeur/' + (customer.phone ? customer.phone : customer.sim_number), customer).subscribe(
      (data) => {
        if(!this._authService.isAuthenticated()) this.processTrustNsign();
        else this._notificationService.showNotification(this.notificationsRevendeur[data.data].title, this.notificationsRevendeur[data.data].description, this.notificationsRevendeur[data.data].callback);
      },
      (error) => {
        this._notificationService.showNotification(this.translateService.instant(error.error.message + "_title"), this.translateService.instant(error.error.message + "_description"));
        this._consoleService.debug('FormIdComponent - validateOtp - error', 'o', 'error', error);
      }
    );
  }

  processTrustNsign() {
    const data = {
      customerFileId: this.customerFileService.customerFile.customerFileId,
    };

    this._apiService.post('trust-and-sign-folder', data).subscribe((data) => {
      this._consoleService.debug('FormIdComponent - processTrustNsign', 'o', 'data', data);
      if(this.customerFileService.customerFile.firstname) localStorage.setItem('name', this.customerFileService.customerFile.firstname);
      localStorage.setItem('token', data.data.token);
      localStorage.setItem('access_token', data.data.access_token);
      localStorage.setItem('trust_and_sign_client_file_uuid', data.data.trust_and_sign_client_file_uuid);
      // Remplace l'URL sans recharger la page
      window.history.replaceState({}, '', '/identification/' + data.data.token);

      this.customerFileService.customerFile.clientFileUuid = data.data.trust_and_sign_client_file_uuid;

      this.customerFileService.otpForm.reset();
      this.otpRequest = false;
      this.showNetheosFrame.emit(true);
    });
  }

  showOTPForm() {
    this.countdownTime = parseInt(this._settingService.getByKey("otp_duration"));
    // Préfixer le numéro de téléphone avec 689 si nécessaire
    let phone = this._toolsService.formatPhone(this.customerFileService.customerForm.value.phone);
    this.customerFileService.customerForm.patchValue({
      phone: phone
    });

    const dataToSend = {
      to: phone,
    };

    this._notificationService.showLoading();
    this._apiService.post('send-otp', dataToSend).subscribe(
      (data) => {
        this._consoleService.debug('FormIdComponent - updateCustomer', 'o', 'data', data);
        this.otpRequest = true;
        this.customerFileService.customerFile.customerFileId = data.data.customer_file_id;
        this.startCountdown();
      },
      (error) => {
        if(error.error.message == "identification_esim_already_done") {
          this._notificationService.showNotification(
            this.translateService.instant(error.error.message + "_title", { phone: dataToSend.to }),
            this.translateService.instant(error.error.message + "_description"),
            null,
            [
              {label: 'identification_esim_already_done_button_1_label', callback: 'confirmPhone', target: 'external', color: 'btn-vini-primary'},
              {label: 'identification_esim_already_done_button_2_label', callback: 'clearCustomerForm', target: 'external', color: 'btn-vini-secondary-light'}
            ],
            false
          );
        } else {
          this._notificationService.showNotification(this.translateService.instant(error.error.message + "_title"), this.translateService.instant(error.error.message + "_description"));
          this._consoleService.debug('FormIdComponent - validateOtp - error', 'o', 'error', error);
        }
      }
    );
  }

  validateOtp() {
    // Parcourir tous les champs pour les marquer comme "touché" ou "sale"
    Object.keys(this.customerFileService.otpForm.controls).forEach(cle => {
      const controle = this.customerFileService.otpForm.get(cle);
      controle?.markAsTouched(); // Marque comme touché
      controle?.updateValueAndValidity(); // Met à jour la validité
    });

    if (this.customerFileService.otpForm.invalid) {
      return;
    }

    const customer = this.prepareCustomerDataForRequest();

    customer['otp_code'] = this.customerFileService.otpForm.value.otp_code;
    customer['phone'] = this.customerFileService.customerForm.value.phone;
    customer['customerFileId'] = this.customerFileService.customerFile.customerFileId;
    this._notificationService.showLoading();

    this._apiService.post('verify-otp', customer).subscribe(
      (data) => {
        this._consoleService.debug('FormIdComponent - validateOtp', 'o', 'data', data);
        if (data.status === 'Success') {
          // this.customerFileService.customerFile.phone = data.data.phone;
          this.customerFileService.customerFile.customerId = data.data.customer_id;
          this.customerFileService.customerFile.customerFileId = data.data.id;
          this.customerFileService.showPhoneField = false;
          this.customerFileService.otpForm.reset();
          this.otpRequest = false;
          this.updateCustomer();
        } else {
          this._notificationService.showNotification(this.translateService.instant("generic_error_title"), this.translateService.instant("generic_error_description"));
        }
      },
      (error) => {
        this._notificationService.showNotification(this.translateService.instant(error.error.message + "_title"), this.translateService.instant(error.error.message + "_description"));
        this._consoleService.debug('FormIdComponent - validateOtp - error', 'o', 'error', error);
      }
    );
  }

  startCountdown() {
    this._notificationService.hideNotification();
    this.intervalCountdown = setInterval(() => {
      if (this.countdownTime > 0) {
        this.countdownTime--;
        this.updateCountdownDisplay();
      } else {
        clearInterval(this.intervalCountdown); // Stop countdown when time is up
      }
    }, 1000);
  }

  updateCountdownDisplay() {
    const hours = Math.floor(this.countdownTime / 3600);
    const minutes = Math.floor((this.countdownTime % 3600) / 60);
    const seconds = this.countdownTime % 60;

    this.countdownDisplay = this.pad(hours) + ':' + this.pad(minutes) + ':' + this.pad(seconds);
  }

  pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  resendOtp() {
    this.countdownTime = parseInt(this._settingService.getByKey("otp_duration"));
    this.customerFileService.otpForm.reset();
    this.showOTPForm();
  }

  isFieldInvalid(field: string, formGroup: FormGroup = this.customerFileService.customerForm): boolean {
    const control = formGroup.get(field);
    return control && control.invalid && (control.dirty || control.touched);
  }

  onDateSelect(date: { year: number, month: number, day: number }): void {
    const formattedDate = `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day
      .toString()
      .padStart(2, '0')}`;
    this.customerFileService.customerForm.get('birthdate')?.setValue(formattedDate);
  }
}
