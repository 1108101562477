import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConsoleService } from '../../../shared/services/console/console.service';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
  @Input() customer;
  @Output() languageSelected = new EventEmitter<string>();  // Événement pour signaler la sélection

  languages = [
    { name: 'Français', value: 'fr', flag: 'assets/images/flag-fr.png' },
    { name: 'English', value: 'en', flag: 'assets/images/flag-en.png' },
    // { name: 'Reo Maohi', value: 'pf', flag: 'assets/images/flag-pf.png' }
  ];

  slideOutStates: boolean[] = []; // Gérer l'état de chaque élément pour le slide-out
  langSelected: boolean = false;

  constructor(
    private _translateService: TranslateService,
    private _consoleService: ConsoleService,
    private primengConfig: PrimeNGConfig
  ) {}

  ngOnInit(): void {
    // Initialiser l'état slide-out pour chaque élément
    this.slideOutStates = this.languages.map(() => false);
  }

  storeLanguage(lang: string, index: number) {
    this._consoleService.debug("LanguageComponent - storeLanguage", "b", "lang", lang);
    localStorage.setItem('language', lang);
    this._translateService.use(lang);
    this.setCalendarLanguage(lang);
    this.slideOutStates = this.languages.map(() => true);
    // this.slideOutStates[index] = true; // Déclencher l'animation de sortie pour cet élément
    setTimeout(() => {
      this.langSelected = true;
      this.languageSelected.emit(lang); // Émettre l'événement après l'animation
    }, 500); // Le délai correspond à la durée de l'animation CSS
  }

  calendarLanguage = {
    fr: {
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
      monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
      monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Août", "Sep", "Oct", "Nov", "Déc"],
      today: 'Aujourd\'hui',
      clear: 'Effacer',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Sem',
    },
    pf: {
      dayNames: ["Tapati", "Monire", "Mahana piti", "Mahana toru", "Mahana maha", "Mahana pae", "Mahana mā'a"],
      dayNamesShort: ["Tap", "Mon", "Piti", "Toru", "Maha", "Pae", "Mā'a"],
      dayNamesMin: ["Ta", "Mo", "Pi", "To", "Ma", "Pā", "Ma"],
      monthNames: ["Tenuare", "Fepuare", "Mati", "Eperera", "Me", "Tiunu", "Tiurai", "Atete", "Tetepa", "Atopa", "Novema", "Titema"],
      monthNamesShort: ["Ten", "Fep", "Mat", "Epe", "Me", "Tiu", "Tiu", "Ate", "Tet", "Ato", "Nov", "Tit"],
      today: 'I teie mahana',
      clear: 'Fāaremu',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Heb',
    }
  }
  setCalendarLanguage(lang: string) {
    if(lang == 'fr'){
      this.primengConfig.setTranslation(this.calendarLanguage.fr);
    } else if(lang == 'pf'){
      this.primengConfig.setTranslation(this.calendarLanguage.pf);
    }
  }
}
